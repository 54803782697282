<template>
  <div>
    <StateRenderer v-bind="rents">
      <b-alert
        v-if="rents.data.length === 0"
        show
        variant="secondary"
        class="text-center"
      >
        Nenhum aluguel realizado neste período
      </b-alert>
      <template v-else>
        <b-table
          stacked="sm"
          :fields="fields"
          :items="rents.data"
          striped
          hover
          :busy="rents.loading"
          responsive
        />
      </template>
    </StateRenderer>
  </div>
</template>

<script>
import { reduce } from 'lodash/fp'
import { formatCurrency } from '@/helpers/numbers'
import StateRenderer from '@/components/StateRenderer'

export default {
  name: 'RentsPerUserReportList',
  filters: {
    formatCurrency
  },
  components: {
    StateRenderer
  },
  props: {
    rents: {
      type: Object,
      required: true
    }
  },
  computed: {
    fields: () => [
      {
        key: 'name',
        label: 'Usuário'
      },
      {
        key: 'count',
        label: 'Total de Aluguéis'
      }
    ],
    total() {
      return reduce(
        (result, rent) => (result += rent.amount),
        0,
        this.rents.data
      )
    },
    totalHours() {
      return reduce(
        (result, rent) => (result += rent.hours),
        0,
        this.rents.data
      )
    }
  }
}
</script>
